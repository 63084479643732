import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dbb99a0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searchAgenda pageInit" }
const _hoisted_2 = { class: "pageTitle textColor" }
const _hoisted_3 = { class: "contentsUl" }
const _hoisted_4 = { class: "contentsLi" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_imgBox = _resolveComponent("imgBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, "SESSION SEARCH RESULTS for \"" + _toDisplayString(_ctx.route.query.keyword) + "\" (" + _toDisplayString(_ctx.searchList.length) + " Results)", 1),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchList, (item) => {
        return (_openBlock(), _createElementBlock("li", _hoisted_4, [
          _createVNode(_component_imgBox, {
            style: {"cursor":"pointer"},
            showName: true,
            showBottomText: false,
            data: item,
            width: 350,
            onClick: ($event: any) => (_ctx.goDetail(item))
          }, null, 8, ["data", "onClick"])
        ]))
      }), 256))
    ])
  ]))
}