
import {defineComponent, reactive, toRefs, watch, onBeforeMount, onMounted} from 'vue'
import axios from '@/api/http.js';
import {useRoute, useRouter, onBeforeRouteUpdate} from 'vue-router';
import imgBoxFlex from "@/components/page/imgBoxFlex.vue";
import imgBox from "@/components/page/imgBox.vue";
import { ElLoading } from 'element-plus';


export default defineComponent({
  name: 'searchAgenda',
  components: {
    imgBoxFlex,
    imgBox,
    ElLoading
  },
  setup() {
    // watch(() =>route, (val, old) => {
    //   console.log(val, old)
    // })
    const route = useRoute();
    const router = useRouter();
    const keywordText = route.query.keyword;
    const journeyList = route.query.journey;
    const trackList = route.query.track;
    const areaList = route.query.areasOfInterest;
    onBeforeRouteUpdate((to) => {
      console.log(to, "=====");
      let keyword = to.query.keyword ? to.query.keyword : '';
      let journey = to.query.journey ? to.query.journey : [];
      let track = to.query.track ? to.query.track : [];
      let areasOfInterest = to.query.areasOfInterest ? to.query.areasOfInterest : [];
      // console.log('route',route.query)
      // if (to.query.keyword == '') {
      //   data.searchList = [];
      //   return
      // }
      getData(keyword,journey,track,areasOfInterest);
    });
    const data = reactive({
      searchList: [],
    })
    
    const getData = (keyword, journeyList, trackList, areaList) => {
      // const loading = ElLoading.service({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(255, 255, 255, 0.5)',
      // });
      // if (k == '') {
      //   // loading.close();
      //   return
      // }

      const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/agendas',
        data: {
          meetingId: "a7yyxczuty0qpjo2393gmyad",
          journeyList: journeyList,
          trackList: trackList,
          areaList: areaList,
          keyword: keyword
        }
      }).then(res => {
        data.searchList = res.data.data;
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    getData(keywordText,journeyList,trackList,areaList);
    const goDetail = (data) => {
      router.push({
        name: 'Content',
        query: {id: data.id},
      });
    };
    const refData = toRefs(data);
    return {
      ...refData,
      getData,
      route,
      router,
      goDetail,
      keywordText,
      journeyList,
      trackList,
      areaList,
    }

  }
});
